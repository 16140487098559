.dashboard-side {
  background: rgba($brown2, 0.5);
  transition: all 0.3s ease-in-out;
  float: left;
  position: fixed;
  top: 0;
  z-index: 100;

  .icon-container {
    position: relative;
    left: 0;
    top: 0;
    display: inline-block;
    margin-right: 5px;
  }

  .chat-unread-indicator {
    background-color: #dc3545;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 10px;
  }

  // margin-top: -75px;
  &.collapsed {
    width: 40px;
    .dash-logo {
      opacity: 0;
      display: none;
    }
    .side-menu {
      overflow: visible;
      ul {
        li {
          border-right: 2px solid #343434;
          a {
            height: 57px;
            i {
              opacity: 1 !important;
              margin: 0;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
            .icon-container {
              opacity: 1 !important;
              margin: 0;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 24px;
              height: 24px;
            }
            span {
              transform: translateX(-150px);
              // display: none;
              opacity: 0;
            }
          }
        }
      }
    }
  }

  width: 260px;
  height: 100%;
  min-height: 100vh;
  // @include trans;
  // transition: all .5s linear;
  .dash-logo {
    text-align: center;
    padding: 20px;
    img {
      max-width: 140px;
      width: 100%;
    }
  }

  .side-menu {
    width: 100%;
    // position: fixed;
    // width: 260px;

    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }

    height: calc(100% - 98px);
    background: rgba($brown2, 0.6);
    padding-top: 10px;
    // @include trans;
    // transition: all .3s ease-in-out;
    // overflow: hidden;

    p.collapse-menu {
      font-family: $lato;
      font-weight: 300;
      font-size: 18px;
      position: relative;
      height: 40px;
      margin-right: 10px;
      margin-bottom: 0;
      color: #fff;
      text-align: right;
      cursor: pointer;
    }
    #collapse {
      display: none;
      margin-left: 12px;
      font-size: 18px;

      cursor: pointer;
      // @include trans;
      // transition: all .5s linear;
    }
    ul {
      width: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
      position: relative;
      z-index: 99;
      height: calc(100% + 250px);

      li {
        position: relative;
        // background: #3b4046;
        background: rgba(59, 64, 70, 0.8);
        width: 100%;
        // border-right: 8px solid transparent;
        margin-bottom: 2px;
        // @include trans;
        // transition: all .5s linear;
        > ul.sub-nav {
          // padding: 10px;
          background: darken(#343434, 8%);
          // position: relative;
          width: 260px;
          height: max-content;
          display: none;

          li {
            background: darken(#343434, 8%);
            border-right: none !important;
            width: 100%;

            a {
              font-size: 16px;
              padding: 8px 0 8px 20px;
              height: auto;
              height: 57px;
              line-height: 1.5;
              position: relative;
              display: flex;
              align-items: center;
              i {
                position: relative;
                left: 0;
                top: 0;
              }

              span {
                margin-left: 10px;
                line-height: 1.2;
                display: inline-block !important;
                transform: translatex(0) !important;
              }
            }
            .arrow {
              display: none !important;
            }
          }
        }
        .arrow {
          position: absolute;
          color: #fff;
          font-size: 26px;
          width: 10px;
          z-index: 5;
          top: 50%;
          transform: translateY(-50%);
          right: 5px;
          @include trans;
          // display: none;
          &.arrow-down {
            top: 10px;
            transform: rotateZ(-90deg);
          }
        }
        .toggled-menu {
          width: 220px !important;
          padding-left: 0;
          position: absolute;
          left: 40px;
          top: 0;
          opacity: 1;
          z-index: 99999;
          //  @include trans;
          //  &.visible {
          //    left: 40px;
          //    opacity: 1 !important;
          //    width: 200px !important;
          li {
            border-bottom: 1px solid $light-brown;
            display: block;
            > ul.sub-nav {
              left: 150px;
              // opacity: 0;
            }
          }
          //  }
          li {
            width: max-content;
            padding-left: 20px;
            border-bottom: 1px solid transparent;
            display: block;

            a {
              padding: 8px 0 8px 13px;
              // height: 57px;

              span {
                //  padding-top: 10px;
                // display: none !important;
                opacity: 1;
              }
              i {
                position: absolute !important;
                top: 50% !important;
                transform: translate(0, -50%);
              }
              .icon-container {
                position: absolute !important;
                top: 50% !important;
                left: 0 !important;
                transform: translate(0, -50%);
                width: 24px;
                height: 24px;
              }
            }
          }
        }
        width: 100%;
        i {
          margin-right: 5px;
        }
        a {
          color: #fff;
          font-family: $lato;
          padding: 13px 0 13px 35px;
          height: auto;
          display: block;
          font-size: 16px;
          position: relative;
          // text-transform: capitalize;
          // @include trans;
          // transition: all .5s linear;
          &:hover {
            color: $blue-hover;
          }
          span {
            // opacity: 0;
          }
        }
      }
      .active {
        // border-right: 8px solid $blue-hover;
        > a {
          color: $blue-hover;
        }
      }
    }
  }
}
// .dashboard-side .side-menu ul li .toggled-menu.visible li > ul.sub-nav {
//   opacity: 0;
// }
// .dashboard-side .side-menu ul li .toggled-menu.visible li:hover ul.sub-nav {
//   opacity: 1;
// }
