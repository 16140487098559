.selectedUser {
  background-color: #222 !important;
}

.chat-wrapper {
  // width: calc(100% + 20px);
  width: 100%;
  // height: calc(100vh - 200px);
  // margin-left: -20px;
  background: whitesmoke;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  .users-list {
    width: 350px;
    // height: 100vh;
    background: rgba(59, 64, 70, 1);
    .users-list-header {
      height: 70px;
      // background: rgb(47, 51, 56);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px 0 20px;
      .add-to-chat {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #272a2e;
        border: 2px solid rgba(172, 172, 172, 0.6);
        text-decoration: none;
        cursor: pointer;
        font-size: 32px;
        color: rgba(172, 172, 172, 0.6);
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease;
        &:hover {
          border: 2px solid rgba(172, 172, 172, 0.8);
          color: rgba(172, 172, 172, 0.9);
        }
      }
      input[type='text'] {
        background: transparent;
        border: 1px solid rgba(172, 172, 172, 1);
        width: 220px;
        padding: 10px;
        color: rgba(172, 172, 172, 1);
        border-radius: 10px;
        height: 40px;
        font-size: 14px;
        outline: none;
      }
      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(172, 172, 172, 1) !important;
        font-size: 14px;
      }
      ::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(172, 172, 172, 1) !important;
        font-size: 14px;
      }
      :-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(172, 172, 172, 1) !important;
        font-size: 14px;
      }
      :-moz-placeholder {
        /* Firefox 18- */
        color: rgba(172, 172, 172, 1) !important;
        font-size: 14px;
      }
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      max-height: 550px;
      li {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 20px;
        border-bottom: 1px solid rgba(172, 172, 172, 1);
        cursor: pointer;
        position: relative;
        transition: all 0.3s ease;
        &:hover {
          background: rgb(47, 51, 56);
        }
        &.active {
          background: rgb(47, 51, 56);
        }
        &:last-of-type {
          border-bottom: none;
        }
        img {
          margin-right: 20px;
          width: 38px;
          height: 38px;
          border-radius: 100%;
          border: 1px solid white;
        }
        p {
          color: white;
          font-size: 18px;
          font-weight: 400;
          margin-bottom: 0;
        }
        .desc {
          color: white;
          font-size: 14px;
          font-weight: 300;
          display: block;
        }
        .time {
          position: absolute;
          top: 0px;
          right: 10px;
          p {
            color: whitesmoke;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
          }
        }
        .message-status {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          position: absolute;
          background: #3794ff;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 40px;
          right: 35px;
          p {
            color: white;
            text-align: center;
            font-size: 12px;
            font-weight: bold;
          }
        }
        .status {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          position: absolute;
          left: 67px;
          top: 46px;
          &.active {
            background: #00ff00;
            &:before {
              // content: 'Active';
              display: block;
              color: white;
              text-align: left;
              margin-left: -15px;
              margin-top: 20px;
              font-size: 12px;
              font-weight: 400;
              text-transform: uppercase;
            }
          }
          &.iddle {
            background: #e2d412;
            &:before {
              // content: 'Idle';
              display: block;
              color: white;
              text-align: left;
              margin-left: -7px;
              margin-top: 20px;
              font-size: 12px;
              font-weight: 400;
              text-transform: uppercase;
            }
          }
          &.offline {
            background: #ca0d0d;
            &:before {
              // content: 'Offline';
              display: block;
              color: white;
              text-align: left;
              margin-left: -20px;
              margin-top: 20px;
              font-size: 12px;
              font-weight: 400;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }

  .chat-main-window {
    width: calc(100% - 350px);
    min-height: 600px;
    background: rgb(47, 51, 56);
    position: relative;
    #list-messages {
      display: flex;
      flex-direction: column-reverse;
      overflow: auto;
      padding: 75px 20px 100px 20px;
      width: 100%;
      height: 100%;
      position: absolute;
      overflow-y: auto;
      overflow-x: none;
    }
    // overflow: hidden;
    // .chat {
    //   width: 100%;
    //   height: 100%;
    //   position: absolute;
    //   overflow-y: auto;
    //   padding-bottom: 80px;
    //   ul {
    //     // position: absolute;
    //     list-style: none;
    //     padding: 20px 40px 80px 40px;
    // overflow-y: scroll !important;
    .user-message {
      display: flex;
      min-width: 100%;
      .chat-bubble {
        justify-content: flex-start;
        background: #2aa9d8;
        border-radius: 40px;
        padding: 20px;
        display: flex;
        align-items: flex-start;
        margin: 5px 0;
        p {
          color: whitesmoke;
          word-break: break-word;
        }
        img {
          margin-right: 20px;
          width: 48px;
          height: 48px;
          border-radius: 100%;
          border: 1px solid white;
        }
      }
    }
    .admin-message {
      display: flex;
      min-width: 100%;
      justify-content: flex-end;
      .chat-bubble {
        background: #222;
        border-radius: 40px;
        padding: 20px;
        display: flex;
        align-items: flex-start;
        flex-direction: row-reverse;
        margin: 5px 0;
        p {
          color: whitesmoke;
          font-size: 16px;
          word-break: break-word;
        }
        img {
          margin-left: 20px;
          width: 48px;
          height: 48px;
          border-radius: 100%;
          border: 1px solid white;
        }
      }
      // }
      // }
    }
    .top-chat-component {
      position: absolute;
      top: 0;
      left: 0;
      border-bottom: 1px solid rgba(172, 172, 172, 1);
      // border-bottom: none;
      width: 100%;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;
      z-index: 99999;
      background-color: #2f3338;
      i {
        font-size: 32px;
        padding: 0 10px;
      }
    }
    .bottom-chat-component {
      position: absolute;
      bottom: 0;
      left: 0;
      border-top: 1px solid rgba(172, 172, 172, 1);
      background: #25282c;
      width: 100%;
      height: 90px;
      z-index: 99999;
      .chat-button {
        border: none;
        outline: none;
        box-shadow: none;
        background-color: transparent;
      }
      .textarea-holder {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 10px;
      }
      input[type='text'] {
        outline: none;
        background: #25282c;
        color: rgb(199, 199, 199);
        border: none;
        min-width: 100%;
        min-height: 100%;
        padding: 10px 60px 10px 40px;
        font-size: 18px;
      }
      textarea {
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        background-color: transparent;
        outline: none;
        color: white;
        resize: none;
      }
      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(172, 172, 172, 1) !important;
        font-size: 18px;
      }
      ::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(172, 172, 172, 1) !important;
        font-size: 18px;
      }
      :-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(172, 172, 172, 1) !important;
        font-size: 18px;
      }
      :-moz-placeholder {
        /* Firefox 18- */
        color: rgba(172, 172, 172, 1) !important;
        font-size: 18px;
      }
    }
  }
}

.unread-messages {
  border-radius: 10px;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  background-color: #dc3545;
  display: flex;
  align-items: center;
  justify-content: center;
}
